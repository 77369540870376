import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
import AutoSubmit from '@stimulus-components/auto-submit'

const application = Application.start();
// Manually load plugins
application.register('auto-submit', AutoSubmit)
// Automatically load all filed ending with _controllers.js
const controllers = import.meta.glob("./**/*_controller.js", { eager: true });
registerControllers(application, controllers);
