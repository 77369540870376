import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {}

  handleRedirect(e) {
    if(e.detail.response.redirected && e.detail.response.url) {
      event.preventDefault();
      let url = e.detail.response.url
      event.detail.visit(url)
    }
  }

  addLoading() {
    this.element.classList.add('loading');
  }

  removeLoading() {
    this.element.classList.remove('loading');
  }

  disconnect() {
    this.removeLoading(); // Make sure to remove to loader in case the page-back button is used
  }
}
