import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ['header', 'content']
  static  values = {
    contentPath: String
  }
  connect() {}

  addLoading() {
    this.element.classList.add('loading');
  }

  removeLoading() {
    this.element.classList.remove('loading');
  }

  isCollapsedState(){
    return this.element.classList.contains('collapsed') || !this.element.classList.contains('expanded')
  }

  isExpandedState(){
    return this.element.classList.contains('expanded')
  }

  toggle(_event){
    if (this.isExpandedState()) {
      this.collapse()
    } else {
      this.expand()
    }
  }
  collapse(){
    // Set this.contentTarget to its current height
    let ct = this.contentTarget
    let elm = this.element
    elm.style.height = this.contentTarget.offsetHeight + this.headerTarget.offsetHeight + 'px'
    elm.classList.add('collapsed')
    elm.classList.remove('expanded')
    elm.style.height = this.headerTarget.scrollHeight + 'px'
    elm.addEventListener('transitionend', () => {
      ct.style.height = null
    }, { once: true})
  }

  expand(){
    let elm = this.element
    elm.style.height = this.headerTarget.offsetHeight + this.contentTarget.offsetHeight + 'px'
    elm.classList.add('expanded')
    elm.classList.remove('collapsed')
    elm.addEventListener('transitionend', () => {
      elm.style.height = null
    }, { once: true})
  }

  toggleContent(event){
    // If the event element clicked is not the header and it or one of it's parents is an anchored element, return
    if (event.target !== this.headerTarget && event.target.closest('a')) {
      return
    }
    if (this.isCollapsedState()) {
      this.contentTarget.src = this.contentPathValue
      this.contentTarget.setAttribute('src', this.contentPathValue)
    } else {
      this.collapse()
    }
  }

  disconnect() {}
}
