import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static values = {
    returnUrl: String
  };

  static targets = ['closeButton'];

  connect() {
    let modal = this;
    this.closeButtonTargets.forEach((button) => {
      button.href = this.returnUrlValue;
      button.addEventListener('click', modal.close.bind(modal));
    });
  }

  closeIfClickedOutside(e) {
    if (e.target === this.element) {
      this.close();
    }
  }

  close(e) {
    if (e) {
      e.preventDefault();
    }
    this.element.classList.add('hidden');
    // If the current pathname is not the same as the returnUrlValue, visit the returnUrl
    if(this.returnUrlValue){
      let url = new URL(this.returnUrlValue, window.location.origin);
      if (window.location.pathname !== url.pathname) {
        Turbo.visit(this.returnUrlValue);
      }
    }
  }

  disconnect() {
    this.element.removeEventListener('click', this.closeIfClickedOutside);
    document.removeEventListener('keyup', this.handleEscapeKey);
  }
}
